export enum EVENT_TYPES {
  LOGIN_VIEWED = 'loginViewed',
  SUBMIT_LOGIN = 'submitLogin',
  SUBMIT_OKTA_LOGIN = 'submitOktaLogin',
  LOGIN_SUCCESS = 'loginSuccess',
  LOGIN_FAILURE = 'loginFailure',
  LOAD_DETAIL = 'loadDetail',
  BIN_CLICKED = 'BINClicked',
  BOOK_SCREEN_VIEWED = 'bookScreenViewed',
  SUBMIT_LOAD_BOOK = 'submitLoadBook',
  BOOKED_LOAD = 'bookedLoad',
  LOAD_BOOK_ERROR = 'loadBookError',
  OFFER_SUBMITTED = 'offerSubmitted',
  OFFER_SCREEN_VIEWED = 'offerScreenViewed',
  OFFER_WAIT_SCREEN_VIEWED = 'offerWaitScreenViewed',
  OFFER_WAIT_SCREEN_CLOSED = 'offerWaitScreenClosed',
  OFFER_ACCEPTED = 'offerAccepted',
  OFFER_TIMEOUT = 'offerTimeout',
  OFFER_ERROR = 'offerError',
  OFFER_NOT_CONSIDERED = 'offerNotConsidered',
  OFFER_REJECTED = 'offerRejected',
  OFFER_COUNTERED = 'offerCountered',
  SUBMIT_SEARCH = 'search',
  SEARCH_RESULTS = 'searchResults',
  LOAD_UNAVAILABLE_404 = 'loadUnavailable',
  SORT_ACTION = 'sortAction',
  RECOMMENDED_LOAD_LIST_VIEWED = 'recommendedLoadListViewed',
  RELOADS_RESULTS = 'reloadsResults',
  ONBOARDING_ERROR = 'onboardError',
  REQUEST_ADVANCE = 'requestAdvance',
  REVIEW_CASH_ADVANCE_SUBMIT = 'reviewCashAdvanceSubmit',
  REQUEST_CASH_ADVANCE_CONTINUE = 'requestAdvanceContinue',
  REVIEW_CASH_ADVANCE_CONTINUE = 'reviewCashAdvanceContinue',
  MY_LOAD_CLICKED = 'myloadClicked',
  ADVANCE_FAIL = 'advanceFail',
  ADVANCE_SUCCESS = 'advanceSuccess',
  VIEW_CASH_ADVANCE_FAIL = 'viewCashAdvanceFail',
  VIEW_CASH_ADVANCE_SUCCESS = 'viewCashAdvanceSuccess',
  UPLOAD_DOCUMENT = 'uploadDocument',
  UPLOAD_DOCUMENT_SUCCESS = 'uploadDocumentSuccess',
  UPLOAD_DOCUMENT_FAILURE = 'uploadDocumentFailure',
  PAYMENTS_TAB_VIEWED = 'paymentsTabViewed',
  NOTIFICATION_TOGGLE = 'notificationToggle',
}
